.neos-nodetypes-form,
.neos-nodetypes-form-form {
	display: block;
	margin: 0 auto;
	max-width: 940px;
	padding: 0 15px;
}

form {
	label {
		font-size: 18px;
		margin-top: 25px;
		font-weight: $font-weight-bold;
		font-family: $font-family-roboto-condensed;
		padding-left: 15px;
	}

	legend {
		display: none;
	}

	textarea, textarea.form-control, input[type="text"], input[type="date"], .date, input[type="password"], input[type="search"], select {
		border: 1px solid map-get(map-get($themes, $current-theme), formBorder);
		background-color: map-get(map-get($themes, $current-theme), backgroundColor);
		width: 100%;
		font-size: 1.125rem;
		padding: 9px 15px;
		border-radius: 4px;

		&.error {
			border: 1px solid map-get(map-get($themes, $current-theme), primaryColor);
		}

		&::-webkit-calendar-picker-indicator,
		&::-webkit-inner-spin-button {
			display: none;
			-webkit-appearance: none;
		}
	}

	textarea {
		height: 240px;
	}

	.actions {
		margin-top: 25px;

		.form-navigation {
			text-align: center;

			ul {
				padding: 0;

				.btn {
					margin-top: 50px;
					margin-bottom: 80px;
					width: 100%;
					padding: 35px;
					border-radius: 50px;
				}
			}
		}

		ul {
			margin: auto;

			li {
				list-style-type: none;
			}
		}
	}

	.gj-datepicker {
		.input-group-append {
			position: absolute;
			right: 0;
			left: auto;
			top: 0;
			z-index: 198;

			i.gj-icon, i.gj-icon:active {
				color: map-get(map-get($themes, $current-theme), primaryColor) !important;
				left: 50%;
				right: auto;
				z-index: 200;
				position: absolute;
				font-size: 21px;
				top: 50%;
				transform: translate(-50%, -50%);
			}

			button {
				&,
				&:active,
				&:focus {
					border: none !important;
					background: transparent !important;
					box-shadow: none !important;
				}

				z-index: 199;
				width: 50px;
				height: 47px;
			}
		}
	}

	h4 {
		text-transform: uppercase;
		font-size: $font-size-base;
		color: map-get(map-get($themes, $current-theme), gray)
	}

	.link-in-checkbox {
		color: map-get(map-get($themes, $current-theme), primaryColor);
	}

	.f3-form-error + span.cr {
		border: 1px solid map-get(map-get($themes, $current-theme), primaryColor);
	}

	.validation-error {
		color: #D52929;
		font-size: 1.125rem;
		font-weight: $font-weight-bold;
		padding: 20px 0 15px;

		& + .validation-container {
			label {
				color: #D52929;
			}

			input {
				background-color: #f8d7da;
				border-color: #D52929;
			}
		}
	}

	.f3-form-error {
		background-color: #f8d7da !important;
		border-color: #D52929 !important;
	}
}

select {
	font-weight: $font-weight-light;
	padding: 6px 35px 6px 25px;
}

.select-box {
	position: relative;
	border: 1px solid #C8C8C8;
	display: inline-block;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	background: white;
	padding: 5px 10px;
	width: 100%;

	&:after {
		content: " ";
		background: map-get(map-get($themes, $current-theme), selectBackground) !important;
		position: absolute;
		right: 20px;
		top: 50%;
		height: 12px;
		width: 18px;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	select::-ms-expand {
		display: none;
	}

	select {
		border: none;
		box-shadow: none;
		background: transparent;
		background-image: none;
		-webkit-appearance: none;

		&:focus {
			outline: none;
		}
	}

}

.checkbox {
	span {
		font-weight: $font-weight-regular;
	}
}

.checkbox label:after {
	content: '';
	display: table;
	clear: both;
}

.checkbox .cr {
	position: relative;
	display: inline-block;
	border: 1px solid #a9a9a9;
	border-radius: .25em;
	width: 33px;
	height: 33px;
	float: left;
	margin-right: 1em;
	margin-top: -6px;
}

.checkbox .cr .cr-icon {
	display: block;
	position: absolute;
	font-size: 1.2em;
	line-height: 0;
	top: 0;
	left: 20%;
	height: 35px;
	width: 35px;
	background: url('../Images/check.png') no-repeat top left;
}

.checkbox label input[type="checkbox"] {
	display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon {
	transform: scale(3) rotateZ(-20deg);
	opacity: 0;
	transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
	transform: scale(1) rotateZ(0deg);
	opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr {
	opacity: .5;
}

.f3-form-error + span {
	border-color: #D52929 !important;

	+ span {
		color: #D52929 !important;
	}
}


#username-parts,
#username-parts-register {

	.sep {
		border-top: 1px solid #C8C8C8;
		border-bottom: 1px solid #C8C8C8;
		padding-top: 7px;
	}

	input {
		width: auto;

		&::placeholder {
			color: #ccc;
		}

		&:focus,
		&:active {
			box-shadow: none;
			border-color: #C8C8C8;
		}
	}

}

#username-parts {
	input {
		&:first-child {
			border-right: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			width: 45px;
			padding-right: 5px;
		}

		&:nth-of-type(2) {
			border-right: 0;
			border-left: 0;
			border-radius: 0;
			width: 95px;
			padding-left: 5px;
			padding-right: 5px;
		}

		&:last-child {
			border-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			width: 45px;
			padding-left: 5px;
		}
	}
}

#username-parts-register {
	input {
		&:first-child {
			border-right: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			width: 45px;
			padding-right: 5px;
		}

		&:last-child {
			border-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			width: 100px;
			padding-left: 5px;
			padding-right: 10px;
		}

	}
}

// red border color on validation error
form .validation-error + .validation-container #username-parts .sep {
	border-top-color: #D52929;
	border-bottom-color: #D52929;
	background-color: #f8d7da;
}
