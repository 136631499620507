#vr-pay {
    h4.payHead {
        cursor: pointer;
    }

    .wpwl-wrapper-cardNumber, .wpwl-wrapper-cvv {
        color: #000 !important;
    }

    .wpwl-control-expiry, .wpwl-control-cardHolder {
        padding: 4px !important;
        font-size: 16px !important;
    }

    .wpwl-hint {
        font-size: 14px;
        hyphens: auto;
    }

    .wpwl-container-card .wpwl-target {
        background: #fff;
        margin-bottom: 1rem;
    }

    .wpwl-message.wpwl-has-error {
        background: #fff;
    }
}
