nelo-faqsearch {
  display: block;

  .btn {
    position: relative;
    .arrow-right {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      left: -2px;
    }
  }
}