nelo-oncollectcustomerportal {
    display: block;

    .btn-print {
        @media print {
            display: none;
        }
    }

    /**
     * ########### Login
     */
    .forgot-password-link {
        position: absolute;
        top: 2px;
        right: 15px;
        @include media-breakpoint-up(lg) {
            right: 0;
        }
    }

    /**
     * ########### Payments
     */
    .payments {
        @media print {
            margin-bottom: 0 !important;
            & > .row > [class*="col"] {
                margin: 0 0 25px !important;
                padding: 0 !important;
            }
        }

        .claim-table-container {
            .claim-table {
                td {
                    padding-top: 16px;
                    padding-bottom: 16px;

                    strong {
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }

        .accordion-payment {
            padding: 0;
            margin-top: 25px;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            border-radius: 25px;

            &, a {
                color: white !important;
                @media print {
                    color: black !important;
                }
            }

            [data-toggle="collapse"] {
                text-align: center;
                font-family: $font-family-roboto-condensed;
                font-size: 1.125rem;
                text-decoration: none !important;
                display: block;
                padding: 14px 20px;
                cursor: pointer;
                letter-spacing: 0.01em;
            }

            .payment-details {
                padding: 14px 20px 25px;
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;

                .select-box {
                    padding: 0;

                    select {
                        height: 60px !important;
                        font-size: 1.125rem;
                    }
                }

                input {
                    font-size: 1.125rem;
                    padding: 15px 15px 14px;
                }

                .btn[class*="-invert"] {
                    border-width: 2px !important;
                    width: 100%;
                    text-align: left;
                    padding-left: 45px;
                    padding-right: 30px;
                    position: relative;

                    svg {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);

                        g {
                            transition: stroke .3s;
                        }
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        svg {
                            g {
                                stroke: map-get(map-get($themes, $current-theme), primaryColor);
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * ########### Claim
     */
    .claim {
        @media print {
            margin-bottom: 0 !important;
        }

        & > .row {
            @media print {
                padding-top: 0 !important;
                margin-top: 0 !important;
                padding-bottom: 0 !important;
                margin-bottom: 15px !important;
            }
        }

        .bg-ultralightgray {
            padding-left: 10%;
            padding-right: 10%;
            @media print {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .claim-table-container {
            .claim-table {
                td {
                    padding-top: 16px;
                    padding-bottom: 16px;

                    strong {
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }
    }

    /**
    * ########### General
    */
    .btn-print {
        position: relative;

        svg {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 20%;
        }
    }

    .btn {
        position: relative;

        .arrow-right {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            left: -2px;
        }
    }
}
