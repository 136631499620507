nelo-references {
  .headline {
    text-align: center;
    margin-bottom: 70px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
      margin: 0;
      font-size: 50px;
      @include media-breakpoint-down(sm) {
        font-size: 20px;
        text-align: left;
      }
    }
  }

  .quotation {
    border: 1px solid #c9c9c9;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 60px 60px 45px;
    text-align: center;
    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
    .tab-pane > span {
      font-size: 35px;
      font-style: italic;
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
      &:before {
        content: "“";
        display: inline-block;
      }
      &:after {
        content: "”...";
        display: inline-block;
      }
      * {
        display: inline;
      }
    }

    .quote-author {
      margin-top: 80px;
      font-size: 20px;
      font-weight: $font-weight-regular;
      @include media-breakpoint-down(sm) {
        font-size: 16px;
        margin-top: 30px;
      }
      .info {
        font-size: 16px;
        font-weight: $font-weight-light;
        @include media-breakpoint-down(sm) {
          font-size: 14px;
        }
      }
    }
    .btn-next {
      display: inline-block;
      margin-top: 30px;
      @include media-breakpoint-down(sm) {
        margin-top: 10px;
        svg {
          height: 30px;
        }
      }
    }
  }

  .logos {
    > .nav {
      position: relative;
      padding: 0 70px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        padding: 0;
      }
      @include media-breakpoint-down(md) {
        display: flex !important;
        padding: 0;
      }
      li {
        position: relative;
        padding-top: 100px;
        width: 20%;
        @include media-breakpoint-down(xl) {
          padding-top: 80px;
        }
        @include media-breakpoint-down(md) {
          display: flex !important;
          width: 33.3333%;
          padding-top: 65px;
          align-items: center;
        }
        @include media-breakpoint-down(sm) {
          padding-top: 40px;
          width: 50%;
        }
        img {
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
          width: auto;
        }
        a {
          display: block;
        }
        a.active {
          img {
            -webkit-filter: none;
            filter: none;
          }
          &:before {
            position: absolute;
            top: -2px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            z-index: 1;
            content: " ";
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 35px solid map-get(map-get($themes, $current-theme), backgroundColor);
            @include media-breakpoint-down(sm) {
              border-left-width: 15px;
              border-right-width: 15px;
              border-top-width: 20px;
            }
          }
          &:after {
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            z-index: 0;
            content: " ";
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 35px solid #c9c9c9;
            @include media-breakpoint-down(sm) {
              border-left-width: 15px;
              border-right-width: 15px;
              border-top-width: 20px;
            }
          }
        }
        @include media-breakpoint-down(md) {
          a {
            max-width: 190px;
            margin: 0 auto;
          }
          &:nth-child(4),
          &:nth-child(5) {
            padding-top: 25px;
            margin: 0 auto;
            position: static;
          }
          &:nth-child(4) {
            a.active {
              &:before,
              &:after {
                left: 25%;
              }
            }
          }
          &:nth-child(5) {
            a.active {
              &:before,
              &:after {
                left: 75%;
              }
            }
          }
        }
        @include media-breakpoint-down(sm) {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            padding-top: 25px;
            margin: 0;
            position: static;
          }
          &:nth-child(4) {
            a.active {
              &:before,
              &:after {
                left: 50%;
              }
            }
          }
          &:nth-child(5) {
            a.active {
              &:before,
              &:after {
                left: 50%;
              }
            }
          }
        }
      }
    }
  }
}