@mixin buttons($parent, $bg-color, $bg-hover-color, $font-color, $font-hover-color, $transition: all .3s ease-in-out, $font-size: 18px) {
  #{$parent} {
    background-color: $bg-color;
    padding: 14px 50px;
    color: $font-color !important;
    border: 1px solid $bg-color !important;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    font-size: $font-size;
    line-height: 1.15;
    text-align: center;
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    min-width: 220px;
    font-weight: 700;
    font-family: $font-family-roboto-condensed;
    text-decoration: none !important;
    @include media-breakpoint-down(md) {
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }
    @include media-breakpoint-down(sm) {
      font-size: calc(#{$font-size} - 2px);
    }
    &:hover,
    &:focus,
    &:active {
      background-color: $bg-hover-color !important;
      color: $font-hover-color !important;
    }
  }
}


@mixin buttons-invert($parent, $bg-color, $bg-hover-color, $font-color, $font-hover-color, $transition: all .3s ease-in-out, $font-size: 18px) {
  #{$parent} {
    background-color: $bg-color;
    padding: 14px 50px;
    color: $font-color !important;
    border: 1px solid $font-color !important;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    font-size: $font-size;
    line-height: 1.15;
    text-align: center;
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    min-width: 220px;
    font-weight: 700;
    font-family: $font-family-roboto-condensed;
    text-decoration: none !important;
    @include media-breakpoint-down(md) {
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      min-width: initial;
    }
    @include media-breakpoint-down(sm) {
      text-align: left;
      font-size: calc(#{$font-size} - 2px);
    }
    &:hover,
    &:focus,
    &:active {
      background-color: $font-color !important;
      color: $bg-color !important;
      border-color: $bg-color !important;
    }
  }
}