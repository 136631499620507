nelo-faqtext {
  display: block;
  margin: 25px 0 35px;
  cursor: pointer;
  & + nelo-faqtext {
    margin-top: 0;
  }
  .faq-question {
    display: table;
    width: 100%;
    table tr {
      td {
        &:first-child {
          width: 50px;
        }
        vertical-align: top;
        color: map-get(map-get($themes, $current-theme), primaryColor);
        font-weight: $font-weight-bold;
        -webkit-transition: color .3s;
        -moz-transition: color .3s;
        -ms-transition: color .3s;
        -o-transition: color .3s;
        transition: color .3s;
      }
    }
    &.opened {
      svg {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
      table tr {
        td {
          color: map-get(map-get($themes, $current-theme), textColor);
        }
      }
    }
    svg {
      position: relative;
      top: -2px;
      vertical-align:middle;
      -webkit-transition: transform .3s ease-in-out;
      -moz-transition: transform .3s ease-in-out;
      -ms-transition: transform .3s ease-in-out;
      -o-transition: transform .3s ease-in-out;
      transition: transform .3s ease-in-out;
      margin-right: 10px;
    }
  }
  .faq-answer {
    display: none;
    margin-left: 50px;
    margin-top: 20px;
  }
}

nelo-faq-category {
  display: block;
  margin: 25px 0;
}
nelo-faq-category-listing {
  display: block;
  margin: 25px 0;
}