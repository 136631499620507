nelo-textimage {
  border-bottom: 1px solid transparent;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
  section.content {
    padding-bottom: 28px;
    p {
      margin-bottom: 0;
    }
  }
  .image-logo {
    .lazy-image {
      width: 50%;
      margin: auto;
    }
  }
  .image-icon {
    .lazy-image {
      z-index: 100;
      width: 50%;
      margin: auto;
    }
  }
  .image-icon-shadow {
    overflow: visible;
    position: absolute;
    transform: scale(0.8, 0.7) skewY(-21deg) skewX(46deg) translate(-39%, 138%);
    z-index: 99;
    img {
      width: 25%;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%) blur(0.5rem);
      opacity: 0.5;
      filter: alpha(opacity=50); /* For IE8 and earlier */
    }
  }
  .row {
    margin: 0;
  }
  [data-class="text-right"] {
    .text {
      @include media-breakpoint-down(sm) {
        padding: 0;
        order: 2;
        flex: 0 0 100%;
      }
    }
    .image {
      @include media-breakpoint-down(sm) {
        order: 1;
        flex: 0 0 100%;
        .lazy-image {
          padding-bottom: 25px;
        }
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        img {
          width: 100%;
        }
      }
    }
  }
  [data-class="text-left"] {
    .text {
      @include media-breakpoint-down(sm) {
        padding: 0;
        order: 2;
        flex: 0 0 100%;
      }
    }
    .image {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        img {
          width: 100%;
        }
      }
      @include media-breakpoint-down(sm) {
        order: 1;
        flex: 0 0 100%;
        .lazy-image {
          padding-bottom: 25px;
        }
      }
    }
  }
  [class*="flex-"] {
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .flex-30 {

  }
  figure {
    margin-bottom: 1.5rem;
  }
}