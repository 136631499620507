.popover {
  max-width: 425px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: map-get(map-get($themes, $current-theme), primaryColor);
  border: 1px solid map-get(map-get($themes, $current-theme), primaryColor);
  padding: 34px;
  color: white;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  font-family: $font-family-open-sans;
  @include media-breakpoint-down(sm) {
    max-width: 360px;
  }
  .popover-body {
    color: white;
    padding: 0;
    .image {
      margin-top: 25px;
    }
  }
  &.bs-popover-top {
    margin-top: 50px !important;
  }
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 30px;
  height: 10px;
  margin: 0 -15px;
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow,
.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: -8px;
  bottom: auto;
  &::before,
  &::after {
    border-width: 0 13px 13px 13px;
    border-bottom-color: map-get(map-get($themes, $current-theme), primaryColor);
  }
}