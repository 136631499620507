$themes: (
		green-black: (
				primaryColor: #64a023,
				primaryTextColor: #fff,
				primaryHeadlineColor: #fff,
				secondaryColor: #fff,
				secondaryTextColor: #363636,
				secondaryHeadlineColor: #64a023,
				backgroundColor: #F7F7F7,
				textColor: #363636,
				linkColor: #363636,
				linkColorHover: #64a023,
				borderColor: #eee,
				btnFontSizeBase: 18px,
				btnPrimaryTransform: all .3s ease-in-out,
				btnPrimaryTextColor: #64a023,
				btnPrimaryTextHoverColor: #fff,
				btnPrimaryBgColor: #fff,
				btnPrimaryBgHoverColor: #64a023,
				btnPrimaryBorder: 1px solid #fff,
				btnSecondaryTransform: all .3s ease-in-out,
				btnSecondaryTextColor: #fff,
				btnSecondaryTextHoverColor: #64a023,
				btnSecondaryBgColor: #64a023,
				btnSecondaryBgHoverColor: #fff,
				btnSecondaryBorder: 1px solid #64a023,
				btnTertiaryTransform: all .3s ease-in-out,
				btnTertiaryTextColor: #fff,
				btnTertiaryTextHoverColor: #4A4A4A,
				btnTertiaryBgColor: #4A4A4A,
				btnTertiaryBgHoverColor: #fff,
				btnTertiaryBorder: 1px solid #4A4A4A,
				btnInfoTextColor: #363636,
				btninfoTextHoverColor: #fff,
				btnInfoBgColor: #fff,
				btnInfoBgHoverColor: #fff,
				btnInfoBorder: none,
				formBorder: #C8C8C8,
				shadowColor: #a2a2a2,
				ultralightGray: #E5E5E5,
				lightGray: #4A4A4A,
				gray: #9B9B9B,
				darkGray: #333333,
				selectBackground: url('../Images/select-arrow.png') no-repeat top left
		),
		orange-white: (
				primaryColor: #EC5B0C,
				primaryTextColor: #fff,
				primaryHeadlineColor: #fff,
				secondaryColor: #fff,
				secondaryTextColor: #363636,
				secondaryHeadlineColor: #EC5B0C,
				backgroundColor: #F7F7F7,
				textColor: #363636,
				linkColor: #363636,
				linkColorHover: #EC5B0C,
				borderColor: #eee,
				btnFontSizeBase: 18px,
				btnPrimaryTransform: all .3s ease-in-out,
				btnPrimaryTextColor: #EC5B0C,
				btnPrimaryTextHoverColor: #fff,
				btnPrimaryBgColor: #fff,
				btnPrimaryBgHoverColor: #EC5B0C,
				btnPrimaryBorder: 1px solid #fff,
				btnSecondaryTransform: all .3s ease-in-out,
				btnSecondaryTextColor: #fff,
				btnSecondaryTextHoverColor: #EC5B0C,
				btnSecondaryBgColor: #EC5B0C,
				btnSecondaryBgHoverColor: #fff,
				btnSecondaryBorder: 1px solid #EC5B0C,
				btnInfoTextColor: #363636,
				btninfoTextHoverColor: #fff,
				btnInfoBgColor: #fff,
				btnInfoBgHoverColor: #fff,
				btnInfoBorder: none,
				formBorder: #C8C8C8,
				shadowColor: #a2a2a2,
				ultralightGray: #E5E5E5,
				lightGray: #4A4A4A,
				gray: #9B9B9B,
				darkGray: #333333,
				selectBackground: url('../Images/select-arrow-orange.png') no-repeat top left
		),
);
