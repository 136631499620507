[data-mt="1"] {
  display: block;
  margin-top: 100px;
  @include media-breakpoint-down(lg) {
    margin-top: 75px
  }
  @include media-breakpoint-down(md) {
    margin-top: 50px
  }
  @include media-breakpoint-down(sm) {
    margin-top: 35px;
  }
}

[data-mb="1"] {
  display: block;
  margin-bottom: 100px;
  &.removeMarginBottom {
    margin-bottom: 0px;
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 75px
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 50px
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 35px;
  }
}

@mixin marginClassTopBottom($distance, $unit) {
  .m-#{$distance}#{$unit} {
    margin-top: #{$distance}#{$unit} !important;
    margin-bottom: #{$distance}#{$unit} !important;
    @include media-breakpoint-down(md) {
      margin-top: calc(#{$distance}#{$unit} / 2) !important;
      margin-bottom: calc(#{$distance}#{$unit} / 2) !important;
    }
  }
}

@include marginClassTopBottom(50, px);
@include marginClassTopBottom(100, px);
@include marginClassTopBottom(150, px);
@include marginClassTopBottom(200, px);
@include marginClassTopBottom(250, px);