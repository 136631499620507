@import "variables";
@import "themes";

body {
  font-family: $font-family-open-sans;
  font-weight: $font-weight-light;
  font-size: $font-size-base;
  color: $font-color-base;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base-xs;
  }

  @include media-breakpoint-down(md) {
    word-wrap: break-word;
    overflow-wrap: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
    font-family: $font-family-roboto-condensed;
    font-weight: $font-weight-bold;
  }

  h1, .h1 {
    font-size: 3.6rem;
    margin-bottom: .5em;
    @include media-breakpoint-down(md) {
      font-size: 3rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  h2, .h2 {
    font-size: 2.5rem;
    margin-bottom: .5em;
    @include media-breakpoint-down(md) {
      font-size: 2.5rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  h3, .h3 {
    font-size: 2rem;
    margin-bottom: .5em;
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
  }

  h4, .h4 {
    margin-bottom: 1em;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }
}

a {
  font-weight: $font-weight-regular;
  @include transition(color .3s ease-out);
  @include themify($themes) {
    color: themed('linkColor');
  }
  &:hover {
    text-decoration: none;
    @include themify($themes) {
      color: themed('linkColorHover');
    }
  }

  &[href^="tel:"],
  &[href^="mailTo:"] {
    font-family: $font-family-roboto-condensed;
    font-weight: $font-weight-bold;
    text-decoration: none !important;
  }
}

p {
  &:last-child {
    margin-bottom: 0;
  }

  & + h2,
  & + .h2,
  & + h3,
  & + .h3,
  & + h4,
  & + .h4,
  & + h5,
  & + .h5 {
    margin-top: 1em;
  }
}
ul {
  padding-left: 20px;
}