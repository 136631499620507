#footer {
	font-size: 14px;
	padding-bottom: 30px;
	h4, .h4, h5, .h5 {
		font-size: 16px;
	}

	[data-mb="1"],
	nav.neos-nodetypes-menu {
		display: block;
		margin-bottom: 30px;
	}

	nav.neos-nodetypes-menu {
		ul {
			li {
				list-style: none;
				margin-bottom: 20px;
			}
		}
	}

	a {
		font-size: 15px;
		font-weight: $font-weight-regular;
		@include themify($themes) {
			color: themed('linkColorHover');
		}
		&:hover {
			@include themify($themes) {
				color: themed('linkColor');
			}
		}
	}

	.row {
		@media print {
			display: block;
			flex: 0;
		}
		[class*="col-"] {
			@include media-breakpoint-down(md) {
				-webkit-box-flex: 0;
				-webkit-flex: 0 0 100%;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
			}
			@media print {
				float: left;
				display: table;
				flex: 0;
			}
		}
		.col-md-3 {
			@media print {
				width: 25%;
			}
		}
		.col-md-4 {
			@media print {
				width: 33.33%;
			}
		}
		.col-md-6 {
			@media print {
				width: 50%;
			}
		}
	}
	[data-mb="1"] {
		@include media-breakpoint-down(md) {
			margin-top: 40px;
			margin-bottom: 0;
		}
	}
	.col-md-6 {
		@include media-breakpoint-down(md) {
			padding-top: 35px;
			padding-bottom: 35px;
		}
		[data-mb="1"] {
			@include media-breakpoint-down(md) {
				margin-top: 0;
				margin-bottom: 1rem;
			}
		}
	}
	p:last-child {
		margin: 0;
	}
}