nelo-contact {
  margin-top: 50px;
  margin-bottom: 25px;
  border-bottom: 1px solid transparent;
  @include themify($themes) {
    border-bottom-color: themed('borderColor');
  }
  @include media-breakpoint-down(sm) {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  section.content {
    padding-bottom: 28px;
    p {
      margin-bottom: 0;
    }
    h3, .h3, h4, .h4 {
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }
    .first-col {
      padding-right: 120px;
      @include media-breakpoint-down(lg) {
        padding-right: 30px;
      }
      @include media-breakpoint-down(sm) {
        padding: 15px;
        & + div {
          padding: 15px !important;
        }
      }
      @include media-breakpoint-down(xs) {
        border-bottom: 1px solid #ccc;
        padding: 0 15px 15px;
        & + div {
          padding: 15px 15px 0 !important;
        }
      }
    }
    a {
      color: $font-color-base !important;
    }
  }
}