nelo-teaser {
  margin-top: 50px;
  margin-bottom: 25px;
  section.container {
    p {
      margin-bottom: 0;
    }
    .link {
      margin-bottom: 20px;
      a {
        font-weight: $font-weight-light;
      }
      > div, > p {
        display: inline-block;
        float: left;
      }
      svg {
        margin-left: 15px;
      }
    }

    .text-image {
      &.img-left {
        display: block;
        margin: 0 -15px;
        @include media-breakpoint-down(xs) {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        .first-col {
          display: inline-block;
          float: left;
        }
        .second-col {
          float: left;
          @include media-breakpoint-down(xs) {
            float: none;
            align-items: center;
            display: flex;
            .link {
              margin: 0;
            }
          }
        }
        .last-col {
          float: left;
          display: inline-block;
        }
      }
    }
  }
}