nelo-event {
	display: block;
	.textmarker {
		display: inline;
		padding: 0 5px;
		background: white !important;
		color: map-get(map-get($themes, $current-theme), primaryColor);
	}
	.tile {
		background: white;
		box-shadow: 0 3px 20px map-get(map-get($themes, $current-theme), shadowColor);
		margin-bottom: 30px;
		.tile-top{
			height: 280px;
			position: relative;
			h3 {
				position: absolute;
				left: 0;
				top: 50%;
			}
		}
		.tile-bottom {
			padding: 15px 15px 30px 15px;
		}
	}
	.eventdetails {
		margin-bottom: 50px;
	}
	.eventdates {
		position: relative;
		padding: 40px 100px 90px;
		margin-top: 70px;
		margin-bottom: 40px;
		@include media-breakpoint-down(md) {
			padding: 115px 0 100px;
		}
		&:before {
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100px;
			background: url('../Images/double-rectangle-top.png') top left, url('../Images/double-rectangle-bottom.png') top right;
			background-repeat: no-repeat;
		}
		&:after {
			content: " ";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100px;
			background: url('../Images/double-rectangle-bottom.png') top left, url('../Images/double-rectangle-top.png') top right;
			background-repeat: no-repeat;
		}
		h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
			font-size: 30px;
			margin-bottom: 35px;
			color: map-get(map-get($themes, $current-theme), primaryColor);
		}
		.tile {
			padding: 15px;
			margin-bottom: 10px;
			> .row > div {
				&:nth-child(1),
				&:nth-child(2) {
					font-weight: $font-weight-bold;
				}
			}
			p {
				margin: 0;
			}
			a {
				display: block;
				margin-top: 10px;
				font-weight: $font-weight-bold;
				color: map-get(map-get($themes, $current-theme), primaryColor);
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
		> div:last-child {
			.tile {
				margin-bottom: 35px;
			}
		}
	}
	nelo-banner {
		.textmarker {
			background: transparent !important;
		}
		h2 {
			color: white;
		}
		section {
			margin-bottom: 50px;
		}
	}
	.event-row-ticket {
		margin-bottom: 40px;
		.btn {
			padding: 13px 40px;
			font-size: 16px;
		}
	}
	.oncollectClientShow {
		display: none;
	}

	.col-md-4 {
		.tile {
			.btn {
				@include media-breakpoint-down(md) {
					text-align: center;
				}
			}
		}
	}
}