@mixin backgrounds($parent, $bg-color, $font-color, $headline-color) {
  #{$parent} {
    background-color: $bg-color !important;
    padding: 35px 55px 25px;
    color: $font-color;
    @include media-breakpoint-down(md) {
      padding: 35px 35px 25px;
    }
    @include media-breakpoint-down(sm) {
      padding: 20px;
      background-color: rgba($bg-color, .68) !important;
    }
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
      color: $headline-color;
    }
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus {
      background-color: darken($bg-color, 10%) !important;
    }
  }
}