html, body {
	overflow-x: hidden;
}

#content {
	position: relative;
	padding-top: $header-height;
	@include media-breakpoint-down(sm) {
		padding-top: 70px;
	}
	@media print {
		padding-top: 20px !important;
	}
	a {
		color: map-get(map-get($themes, $current-theme), primaryColor);
		text-decoration: underline;
	}
}

.btn-wrapper {
	display: flex;
}

.btn {
	.cke_focus {
		min-width: 50px;
	}
}

section {
	&.headline-primary {
		h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
			@include themify($themes) {
				color: themed('primaryColor');
			}
		}
	}
}

.back-to-top {
	background: none;
	margin: 0;
	position: fixed;
	bottom: 0;
	right: 0;
	width: 70px;
	height: 70px;
	z-index: 100;
	display: none;
	text-decoration: none;
	background-color: transparent;
	path {
		fill: map-get(map-get($themes, $current-theme), primaryColor);
	}
}

.mouse-pointer {
	cursor: pointer;
}

.fancybox-content {
	background: #fff !important;
}

.error-message {
	color: map-get(map-get($themes, $current-theme), primaryColor);
	margin: 0 15px 25px 15px;
	border: solid 1px map-get(map-get($themes, $current-theme), primaryColor);
	padding: 25px;
}

.margin-top-50 {
	margin-top: 50px;
}

.margin-top-25 {
	margin-top: 25px;
}