.tooltip-indicator {
	color: white;
	border-radius: 50%;
	font-weight: bold;
	margin-left: 15px;
	padding: 0 8px;
	height: 24px;
	width: 24px;
	cursor: pointer;
	text-align: center;
	background-color:  map-get(map-get($themes, $current-theme), primaryColor);
}

.tooltip.show {

}