@import "variables";
@import "themes";

@include backgrounds('.bg-primary',
		map-get(map-get($themes, $current-theme), primaryColor),
		map-get(map-get($themes, $current-theme), primaryTextColor),
		map-get(map-get($themes, $current-theme), primaryHeadlineColor)
);

@include backgrounds('.bg-secondary',
		map-get(map-get($themes, $current-theme), secondaryColor),
		map-get(map-get($themes, $current-theme), secondaryTextColor),
		map-get(map-get($themes, $current-theme), secondaryHeadlineColor)
);

@include backgrounds('.bg-lightgray',
		map-get(map-get($themes, $current-theme), lightGray),
		map-get(map-get($themes, $current-theme), textColor),
		map-get(map-get($themes, $current-theme), textColor)
);

@include backgrounds('.bg-ultralightgray',
		map-get(map-get($themes, $current-theme), ultralightGray),
		map-get(map-get($themes, $current-theme), textColor),
		map-get(map-get($themes, $current-theme), textColor)
);

.bg-primary-headline {
	@include themify($themes) {
		color: themed('primaryTextColor');
	}

	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
		display: inline;
		padding: 0;
		@include themify($themes) {
			background: themed('primaryColor');
			box-shadow: 10px 0 0 themed('primaryColor'), -10px 0 0 themed('primaryColor');
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: block;
			padding: 3px 10px;
			box-shadow: none;
		}
	}
}

body {
	background: map-get(map-get($themes, $current-theme), backgroundColor);
}

@media print {
	.bg-primary {
		background: #fff !important;
	}
	.bg-primary-headline {
		background: transparent !important;

		h2 {
			background: transparent !important;
		}
	}
}