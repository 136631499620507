@include buttons('.btn-primary',
		map-get(map-get($themes, $current-theme), btnPrimaryBgColor),
		map-get(map-get($themes, $current-theme), btnPrimaryBgHoverColor),
		map-get(map-get($themes, $current-theme), btnPrimaryTextColor),
		map-get(map-get($themes, $current-theme), btnPrimaryTextHoverColor),
		map-get(map-get($themes, $current-theme), btnPrimaryTransform),
		map-get(map-get($themes, $current-theme), btnFontSizeBase)
)
@include buttons('.btn-secondary',
		map-get(map-get($themes, $current-theme), btnSecondaryBgColor),
		map-get(map-get($themes, $current-theme), btnSecondaryBgHoverColor),
		map-get(map-get($themes, $current-theme), btnSecondaryTextColor),
		map-get(map-get($themes, $current-theme), btnSecondaryTextHoverColor),
		map-get(map-get($themes, $current-theme), btnSecondaryTransform),
		map-get(map-get($themes, $current-theme), btnFontSizeBase)
)
@include buttons('.btn-tertiary',
		map-get(map-get($themes, $current-theme), btnTertiaryBgColor),
		map-get(map-get($themes, $current-theme), btnTertiaryBgHoverColor),
		map-get(map-get($themes, $current-theme), btnTertiaryTextColor),
		map-get(map-get($themes, $current-theme), btnTertiaryTextHoverColor),
		map-get(map-get($themes, $current-theme), btnTertiaryTransform),
		map-get(map-get($themes, $current-theme), btnFontSizeBase)
)
@include buttons('.btn-info',
		map-get(map-get($themes, $current-theme), btnInfoBgColor),
		map-get(map-get($themes, $current-theme), btnInfoBgHoverColor),
		map-get(map-get($themes, $current-theme), btnInfoTextColor),
		map-get(map-get($themes, $current-theme), btnInfoTextHoverColor),
		map-get(map-get($themes, $current-theme), btnPrimaryTransform),
		map-get(map-get($themes, $current-theme), btnFontSizeBase)
)
@include buttons('.btn-editable',
		map-get(map-get($themes, $current-theme), '#333333'),
		map-get(map-get($themes, $current-theme), '#FFFFFF'),
		map-get(map-get($themes, $current-theme), '#333333'),
		map-get(map-get($themes, $current-theme), btnFontSizeBase)
)
@include buttons-invert('.btn-secondary-invert',
		map-get(map-get($themes, $current-theme), btnSecondaryBgColor),
		map-get(map-get($themes, $current-theme), btnSecondaryBgHoverColor),
		map-get(map-get($themes, $current-theme), btnSecondaryTextColor),
		map-get(map-get($themes, $current-theme), btnSecondaryTextHoverColor),
		map-get(map-get($themes, $current-theme), btnSecondaryTransform),
		map-get(map-get($themes, $current-theme), btnFontSizeBase)
)

.btn-info {
	font-weight: $font-weight-light;
	position: relative;
	padding-right: 80px;
	min-height: 75px;
	display: flex;
	align-items: center;
	svg {
		position: absolute;
		right: 10px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	&:hover,
	&:focus,
	&:active {
		@include themify($themes) {
			background: themed('btnPrimaryBgHoverColor') !important;
			color: themed('btnPrimaryTextHoverColor') !important;
		}
	}
}
.btn-editable {
	width: 200px;
}
