nelo-banner {
  section.content {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100%;
    @include media-breakpoint-down(lg) {
      background-size: cover;
    }
    @media print {
      background: none !important;
      margin: 0;
    }
    .btn {
      margin-top: 30px;
      margin-bottom: 20px;
      min-width: 290px;
      @include media-breakpoint-down(sm) {
        min-width: initial;
        margin: 10px 0;
      }
      &.btn-primary {
        width: 100%;
        padding: 15px 35px;
        font-size: 24px;
        @include media-breakpoint-down(sm) {
          font-size: 16px;
          padding: 12px 15px;
          min-width: initial;
        }
      }
    }
    [class*="bg-"] {
      margin-top: 35px;
      margin-bottom: 65px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 35px;
      }
    }
    .flex-40,
    .flex-50,
    .flex-60,
    .flex-70 {
      @include media-breakpoint-down(md) {
        flex: 0 0 75%;
      }
    }
    .flex-80,
    .flex-90 {
      @include media-breakpoint-down(md) {
        flex: 0 0 100%;
      }
    }
    [class*="flex-"] {
      @include media-breakpoint-down(sm) {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      @include media-breakpoint-down(sm) {
        display: block;
      }
      @media print {
        flex: 0 0 100%;
        padding: 0 !important;
      }
    }
    h1, .h1, h2, .h2, h3, .h3 {
      font-size: 3.75rem;
      margin-bottom: 30px;
      @include media-breakpoint-down(lg) {
        font-size: 55px;
      }
      @include media-breakpoint-down(md) {
        font-size: 45px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 23px;
      }
    }
    span {
      &.textmarker {
        h1, .h1, h2, .h2, h3, .h3 {
          display: inline;
        }
      }
    }
    [data-position="left"] {
      @include media-breakpoint-up(sm) {
        padding-right: 0;
      }
    }
    [data-position="right"] {
      float: right;
    }
    [data-position="center"] {
      display: block;
      margin: 0 auto;
    }
    .bg-primary-headline {
      @media print {
        margin: 0 0 30px !important;
        text-align: left !important;
        color: black
      }
    }
  }

  .btn-info-container {
    align-self: flex-end;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 15px;
    }
    a.btn-info {
      min-width: auto;
      margin-top: 0;
      margin-bottom: 50px;
      @include media-breakpoint-down(lg) {
        float: right;
      }
      @include media-breakpoint-down(sm) {
        font-size: 14px;
        margin-bottom: 25px;
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: table;
        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }

  & + nelo-textimage {
    margin-top: 50px;
  }
}