#header {
	z-index: 1000;
	position: fixed;
	top: 0;
	width: 100vw;
	height: $header-height;
	@include media-breakpoint-down(md) {
		height: auto;
	}
	@include themify($themes) {
		border-bottom-color: themed('borderColor');
		background-color: themed('backgroundColor');
	}
	@media print {
		position: relative;
		margin-top: 30px;
	}

	.container .d-flex {
		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
		}

		> div {
			&:nth-child(1) {
				@include media-breakpoint-down(md) {
					order: 1;
					flex: 0 0 50%;
				}
			}

			&:nth-child(2) {
				@include media-breakpoint-down(md) {
					order: 3;
					flex: 0 0 100%;
				}
			}

			&:nth-child(3) {
				@include media-breakpoint-down(md) {
					order: 2;
					flex: 0 0 30%;
					padding: 18px 0 13px;
				}
				@include media-breakpoint-down(xs) {
					padding: 16px 0 13px;
				}

				.login-button {
					@include media-breakpoint-down(md) {
						margin-top: 0;
						margin-left: auto;
						float: right;
					}
				}
			}
		}
	}

	.logo {
		padding-top: 15px;
		padding-bottom: 15px;
		@include media-breakpoint-down(lg) {
			img {
				height: 25px;
			}
		}
		@include media-breakpoint-down(md) {
			img {
				height: 21px;
				width: auto;
			}
		}
		@include media-breakpoint-down(xs) {
			img {
				height: 18px;
				width: auto;
			}
		}
	}

	.login-button {
		display: block;
		margin-top: 20px;
		color: white;
		background-color: map-get(map-get($themes, $current-theme), primaryColor);
		font-size: 14px;
		padding: 3px 15px;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
		font-family: $font-family-roboto-condensed;
		@include media-breakpoint-down(md) {
			font-size: 13px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 12px;
			padding: 1px 15px;
		}
	}
}

#navigation {
	@include clearfix();
	padding-top: 24px;
	@include media-breakpoint-down(md) {
		padding-top: 3px;
		padding-bottom: 5px;
	}

	> ul {
		padding: 0;
		margin: 0;
		list-style: none;

		> li {
			float: left;
			padding: 0 15px;
			@include media-breakpoint-down(lg) {
				padding: 0 5px;
			}
			@include media-breakpoint-down(md) {
				padding: 0 12px;
			}
			@include media-breakpoint-down(sm) {
				padding: 5px 20px 5px 0;
			}

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			&.current {
				a {
					color: map-get(map-get($themes, $current-theme), primaryColor);
				}
			}

			a {
				font-size: 16px;
				font-weight: $font-weight-bold;
				font-family: $font-family-roboto-condensed;
				text-transform: uppercase;
				color: map-get(map-get($themes, $current-theme), darkGray);

				&:hover {
					color: map-get(map-get($themes, $current-theme), primaryColor);
				}

				@include media-breakpoint-down(md) {
					font-size: 14px;
					font-weight: $font-weight-bold;
				}
				@include media-breakpoint-down(sm) {
					font-weight: $font-weight-light;
				}
			}
		}
	}
}