.pointer {
	cursor: pointer;
}
.float-xl-right {
	@include media-breakpoint-up(xl) {
		float: right;
	}
}
.container .container {
	max-width: initial;
	padding: 0;
	min-width: initial !important;
}

.table-fixed {
	table-layout: fixed !important;
}

select {
	cursor: pointer;
}