/* ########## current theme ############# */
$current-theme: orange-white;

// ########### fonts
$font-family-open-sans: 'Open Sans', sans-serif;
$font-family-roboto-condensed: 'Roboto Condensed', sans-serif;

$font-weight-light: 300;
$font-weight-semi-light: 400;
$font-weight-regular: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$font-color-base: #363636;

$font-size-base: 16px;
$font-size-base-xs: 14px;

// ########### general
$header-height: 60px;


